const home_page = {
    name: "Arnav Aggarwal",
    title: "I Am Arnav",
    description: "Insert description here",
}

const socialMediaLinks = {
    github: "https://github.com/arnavagg89",
    linkedin: "https://www.linkedin.com/in/arnavagg89/",
    gmail: "a63aggar@uwaterloo.ca",
}

const universityDetails = {
    name:"University Of Waterloo",
    subtext:"",
    startDate:"September 2020",
    endDate:"April 2025 (expected)",
    degree:"Bachelor of Computer Science, Specialization in Artifical Intelligence",
    logo: "portfolio/src/assets/images/uwaterloo.png",
    bulletPoint1: "here is the first bullet point",
    bulletPoint2: "here is the second bullet point",
    bulletPoint3: "here is the third bullet point"
}
export {home_page, socialMediaLinks, universityDetails};